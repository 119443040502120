<template>
  <main>
    <header>
      <nav
        class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div class="flex flex-1" />
        <span class="flex items-center justify-center gap-x-4">
          <img
            class="h-8 w-8"
            :src="CdnConfig.healisticIconSvg"
            alt="Healistic"
          >
          <span class="text-xl font-semibold text-viola-400">Healistic</span>
        </span>
        <div class="flex flex-1" />
      </nav>
    </header>
    <!-- <AppBanner /> -->

    <slot />
  </main>
</template>
